import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      Main: "main",
      Product: "product",
      "About Us": "About Us",
      Contacts: "Contacts",
      "Contact us": "Contact us",
      "With care for the environment": "With care for the environment",
      "Our company description":
        "Our company, in cooperation with the National Academy of Sciences of Ukraine and a number of European companies, is actively engaged in the development, implementation, and operation of modern environmentally friendly technologies and equipment for effective industrial waste management.",
      "Our solutions are aimed at reducing environmental impact and ensuring sustainable development.":
        "Our solutions are aimed at reducing environmental impact and ensuring sustainable development.",
      "Read more": "Read more",

      "SM 0/40/70 mixture": "SM 0/40/70 mixture",
      "SM 0/40/70 description":
        "We offer a new, high-quality product - a mineral mixture differentiated by fractional composition. The technology was developed with the participation of the Gas Institute of the National Academy of Sciences of Ukraine.",
      "The company has its own physical and chemical laboratory for research":
        "The company has its own physical and chemical laboratory for research",
      "Address for correspondence:": "Address for correspondence:",
      "Baranivskyi district, Pershotravensk urban-type settlement, 7 Nezalezhnosti str.":
        "Baranivskyi district, Pershotravensk urban-type settlement, 7 Nezalezhnosti str.",
      "Phone:": "Phone:",
      "Mail:": "Mail:",
      "We are on social media:": "We are on social media:",
      Name: "Name",
      Phone: "Phone",
      Message: "Message",
      Send: "Send",

      "Name is required": "Name is required",
      "Phone is required": "Phone is required",
      "Email is required": "Email is required",
      "Invalid phone number format": "Invalid phone number format",
      "Invalid email address": "Invalid email address",
      "Message is required": "Message is required'",
      "Thank you for contacting us, please wait for a call":
        "Thank you for contacting us, please wait for a call",

      "Mixture of SM 0/40/70": "Mixture of SM 0/40/70",
      "The SM 0/40/70 mixture description":
        "The SM 0/40/70 mixture is the result of complete processing of stabilized ash that has been kept in the ash pit under a layer of water for a long time by drying and further separation at a modern production complex using advanced technology. ",
      "The separation technology description":
        "The separation technology was developed with the participation of the Gas Institute of the National Academy of Sciences of Ukraine based on computer modeling of the process and calculation of the behavior of small particles in the air flow. This is a very efficient fractionation method that produces the highest quality raw material processing results.",
      "Our mineral mixture SM 0/40/70 description":
        "Our mineral mixture SM 0/40/70 is not an ordinary untreated fly ash from TPP filters, but a high-quality additive - a filler for cement",
      "Mixture and concrete": "Mixture and concrete",
      "The introduction of the mixture description":
        "The introduction of the mixture into concrete (foam concrete, aerated concrete) in the amount of 15...25% by weight of cement leads to an improvement in the entire set of properties of the concrete mix and finished concrete. ",
      "Due to the spherical shape of the particles description":
        'Due to the spherical shape of the particles, the mixture works as a "mineral plasticizer", reducing the water requirement of the concrete mix, which reduces cement consumption and reduces the cost of concrete.',
      "Due to the particle size description":
        "Due to the particle size of 40...70 microns (in the absence of carbon), the mixture effectively fills the pores between larger aggregates (sand and gravel), which increases the density of concrete, improves its durability, frost resistance, and water resistance",
      "The main differences between the mixture and ash :":
        "The main differences between the mixture and ash :",
      "1. The SM 0/40/70 description":
        '1. The SM 0/40/70 mixture has a significantly lower content of unburned carbon ("mechanical underburning").',
      "For our product description":
        "For our product, this is a maximum of 2...3%. The DSTU limit is no more than 5%. The ash removal from filters is usually 5%...7%.",
      "Unburned carbon (carbon) description":
        "Unburned carbon (carbon) is a very undesirable impurity that worsens the properties and characteristics of concrete, reduces the resistance of reinforcement, and also spoils the appearance of concrete (black oily spots appear).",
      "2. Due to the long exposure description":
        "2. Due to the long exposure in the wet state, the raw material does not contain unburned lime and magnesium oxide, which are present in fresh filter ash and can cause uneven volume changes in concrete and mortars.",
      "3. Our product is more homogeneous and finer. description":
        "3. Our product is more homogeneous and finer. The main fraction of the SM 0/40/70 mixture is characterised by a residue on a 45 micron sieve of just over 12%. We also distinguish a fraction that completely passes through the 45 micron sieve.",

      "Fields of application:": "Fields of application:",
      "Production of concrete.": "Production of concrete.",
      "Production of aerated concrete.": "Production of aerated concrete.",
      "Production of cement.": "Production of cement.",
      "Production of construction mixtures.":
        "Production of construction mixtures.",
      "At all stages of production description":
        "At all stages of production, Palleta's technical specialists control the quality of the product we supply (chemical composition, physical, mechanical and technological properties).",
      "Laboratory testing of raw materials and products (materials)":
        "Laboratory testing of raw materials and products (materials)",
      "Quality analysis of raw materials for production":
        "Quality analysis of raw materials for production",
      "Selection of recipes for final products":
        "Selection of recipes for final products",
      "Technological consulting": "Technological consulting",
      "Conducting joint or cross-validation studies":
        "Conducting joint or cross-validation studies",
      "Geography of supply:": "Geography of supply:",
      Europe: "Europe",
      Ukraine: "Ukraine",
      "Shipment of products:": "Shipment of products:",
      "Road transport:": "Road transport:",
      "Railway transport": "Railway transport",
      "Maritime transport": "Maritime transport",

      "About Us description 1":
        "8 years of professional experience. One of the main activities of our company is the popularization and implementation of the latest technologies that help reduce the impact and burden on the environment. With the help of best practices, we strive to ensure the circular use of natural resources and industrial waste, taking care of the environment and improving the ecology.",
      "About Us description 2":
        "Our company, in cooperation with the National Academy of Sciences of Ukraine and a number of European companies, develops, implements and operates modern environmentally friendly technologies and equipment for industrial waste management.",
      "About Us description 3":
        "Unlike highly specialized companies that promote specific technologies and try to find raw materials suitable for processing using these technologies, we practice a methodological approach.",
      "Our values": "Our values",
      "We are guided by the company's values in our daily work. OUR VALUES:":
        "We are guided by the company's values in our daily work. OUR VALUES:",
      "ATTENTION TO DETAIL": "ATTENTION TO DETAIL",
      RESPONSIBILITY: "RESPONSIBILITY",
      "Innovation and quality": "Innovation and quality",
      "Transparency and ethics": "Transparency and ethics",
      "Teamwork and cooperation": "Teamwork and cooperation",
      "Corporate newsletter": "Corporate newsletter",
      "Ilya Yanishyn:": "Ilya Yanishyn:",
      "Nature conservation is the responsibility of each of us.":
        "Nature conservation is the responsibility of each of us.",
      "Environmental awareness is the key to a healthy future.":
        "Environmental awareness is the key to a healthy future.",
      "Let's take care of nature today to preserve it for future generations.":
        "Let's take care of nature today to preserve it for future generations.",
    },
  },
  uk: {
    translation: {
      Main: "Головна",
      Product: "Про продукт",
      "About Us": "Про компанію",
      Contacts: "Контакти",
      "Contact us": "Зв’яжіться з нами",
      "With care for the environment": "З турботою про навколишнє середовище",
      "Our company description":
        "Наша компанія, є ексклюзивним дилером 'Зелена Карбонатна Компанія` яка у співпраці з НАН України та низкою європейських компаній, активно займається розробкою, впровадженням та експлуатацією сучасних екологічно безпечних технологій і обладнання для ефективного управління промисловими відходами.",
      "Our solutions are aimed at reducing environmental impact and ensuring sustainable development.":
        "Наші рішення спрямовані на зменшення впливу на навколишнє середовище та забезпечення стійкого розвитку",
      "Read more": "Детальніше",

      "SM 0/40/70 mixture": "Суміш СМ 0/40/70",
      "SM 0/40/70 description":
        "Пропонуємо новий, високоякісний продукт – мінеральну суміш, диференційовану за фракційним складом.",
      "The company has its own physical and chemical laboratory for research":
        "Компанія має власну фізико-хімічну лабораторію для проведення досліджень",
      "Address for correspondence:": "Адреса для кореспонденції:",
      "Baranivskyi district, Pershotravensk urban-type settlement, 7 Nezalezhnosti str.":
        "Баранівський район, селище міського типу Першотравенськ, вул. Незалежності, 7",
      "Phone:": "Телефон:",
      "Mail:": "Пошта:",
      "We are on social media:": "Ми в соцмережах:",
      Name: "Ім’я",
      Phone: "Телефон",
      Message: "Повідомлення",
      Send: "Надіслати",

      "Mixture of SM 0/40/70": "Суміш СМ 0/40/70",
      "The SM 0/40/70 mixture description":
        "Суміш SМ 0/40/70 - це результат повної переробки стабілізованої золи, що тривало витримана в золовідвалі під шаром води, шляхом сушіння та подальшої її сепарації на сучасному виробничому комплексі за прогресивною технологією.",
      "The separation technology description":
        "Технологію сепарації розроблено за участю Інституту газу НАН України на базі комп'ютерного моделювання процесу та розрахунку поведінки дрібних частинок у повітряному потоці. Це дуже ефективний метод фракціонування, який дає найбільш якісний результат переробки сировини.",
      "Our mineral mixture SM 0/40/70 description":
        "Наша мінеральна суміш SМ 0/40/70 - це зовсім не звичайна необроблена зола винесення з фільтрів ТЕС, а якісна добавка – наповнювач до цементу.",
      "Mixture and concrete": "Суміш та бетон",
      "The introduction of the mixture description":
        "Введення суміші до складу бетонів (пенобетонів, газобетонів) в кількості 15...25% від маси цементу призводить до поліпшення цілої сукупності властивостей бетонної суміші та готового бетону.",
      "Due to the spherical shape of the particles description":
        'Завдяки сферичній формі часток, суміш працює як "мінеральний пластифікатор", знижуючи водопотребу бетонної суміші, що дозволяє здешевити бетон.',
      "Due to the particle size description":
        "Завдяки розміру часток в 40...70 мкм (при відсутності карбону) суміш ефективно заповнює пори між крупнішим заповнювачем (піском та щебенем), що підвищує щільність бетону, покращує його довговічність, морозостійкість, водонепроникність.",
      "The main differences between the mixture and ash :":
        "Основні відмінності суміші від золи :",
      "1. The SM 0/40/70 description":
        "1. У суміші SМ 0/40/70 значно нижчий вміст незгорілого вуглецю («механічний недопал»).",
      "For our product description":
        "Для нашого продукту це максимум 2...3%. Обмеження ДСТУ - не більше 5%. У золи винесення з фільтрів зазвичай 5%…7%.",
      "Unburned carbon (carbon) description":
        "Незгорілий вуглець (карбон) – це дуже небажана домішка, яка погіршує властивості та характеристики бетону, знижує стійкість арматури, а також псує зовнішній вигляд бетону (випливають чорні маслянисті плями).",
      "2. Due to the long exposure description":
        "2. Завдяки тривалій витримці у мокрому стані, сировина не містить непогашеного вапна та оксиду магнію, які присутні у свіжій золі з фільтрів та можуть викликати нерівномірну зміну об'єму в бетонах та розчинах.",
      "3. Our product is more homogeneous and finer. description":
        "3. Наш продукт більш однорідний та дрібніший. Основна фракція суміші SМ 0/40/70 характеризується залишком на ситі 45 мікрон трохи більше 12%. Також ми виділяємо фракцію, що повністю проходить через сито 45 мікрон.",

      "Fields of application:": "Сфери застосування:",
      "Production of concrete.": "Виробництво бетону.",
      "Production of aerated concrete.": "Виробництво газобетону.",
      "Production of cement.": "Виробництво цементу.",
      "Production of construction mixtures.":
        "Виробництво будівельних сумішей.",
      "At all stages of production description":
        "На всіх етапах виробництва технічні фахівці Palleta контролюють якість продукту, що ми поставляємо (хімічний склад, фізико-механічні й технологічні властивості).",
      "Laboratory testing of raw materials and products (materials)":
        "Лабораторне дослідження сировини й продуктів (матеріалів)",
      "Quality analysis of raw materials for production":
        "Аналіз якості сировини для виробництва",
      "Selection of recipes for final products":
        "Підбір рецептури для кінцевої продукції",
      "Technological consulting": "Технологічний консалтинг",
      "Conducting joint or cross-validation studies":
        "Здійснення спільних або перехресних досліджень",
      "Geography of supply:": "Географія постачання:",
      Europe: "Європа",
      Ukraine: "Україна",
      "Shipment of products:": "Відвантаження продукції:",
      "Road transport:": "Автомобільний транспорт",
      "Railway transport": "Залізничний транспорт",
      "Maritime transport": "Морський транспорт",

      "About Us description 1":
        "Ми молода, амбіційна компанія, що динамічно розвивається. Співробітники компанії мають великий досвід в сфері комплексної реалізації проектів в енергетиці, чорній, кольоровій металургіі, поводженні з небезпечними та іншими групами відходів.",
      "About Us description 2":
        "Наша компанія у співпраці з НАН України та низкою європейських компаній, займається розробкою, впровадженням та експлуатацією сучасних екологічно безпечних технологій та обладнання для поводження з промисловими відходами.",
      "About Us description 3":
        "Одним з основних напрямків діяльності нашої компанії є популяризація та впровадження новітніх технологій, що сприяють скороченню впливу і навантаження на навколишнє середовище. За допомогою передових практик ми прагнемо забезпечити циклічне використовування природних ресурсів і відходів промисловості, піклуючись про довкілля й поліпшуючи екологію.",
      "Our values": "Наші цінності",
      "We are guided by the company's values in our daily work. OUR VALUES:":
        "У своїй щоденній роботі ми керуємося цінностями компанії. НАШІ ЦІННОСТІ:",
      "ATTENTION TO DETAIL": "УВАЖНІСТЬ ДО ДЕТАЛЕЙ",
      RESPONSIBILITY: "ВІДПОВІДАЛЬНІСТЬ",
      "Innovation and quality": "Інновації та якість",
      "Transparency and ethics": "Прозорість та етика",
      "Teamwork and cooperation": "Командна робота та співпраця",
      "Corporate newsletter": "Корпоративний вісник ",
      "Ilya Yanishyn:": "Янішин Ілля:",
      "Nature conservation is the responsibility of each of us.":
        "Збереження природи — це відповідальність кожного з нас.",
      "Environmental awareness is the key to a healthy future.":
        "Екологічна свідомість — ключ до здорового майбутнього.",
      "Let's take care of nature today to preserve it for future generations.":
        "Дбаймо про природу сьогодні, щоб зберегти її для майбутніх поколінь.",

      "Name is required": "Ім'я обов'язкове",
      "Phone is required": "Телефон обов'язковий",
      "Email is required": "Обов'язкова наявність електронної пошти",
      "Invalid phone number format": "Неправильний формат номера телефону",
      "Invalid email address": "Неправильна адреса електронної пошти",
      "Message is required": "Потрібне повідомлення",
      "Thank you for contacting us, please wait for a call":
        "Дякуємо за звернення, очікуйте дзвінка",
    },
  },
  de: {
    translation: {
      Main: "Haupt",
      Product: "Das Produkt",
      "About Us": "über uns",
      Contacts: "Kontakte",
      "Contact us": "Kontaktiere uns",
      "With care for the environment": "Die Sorge um die Umwelt",
      "Our company description":
        " In Zusammenarbeit mit der Nationalen Akademie der Wissenschaften der Ukraine und einer Reihe europäischer Unternehmen ist unser Unternehmen aktiv an der Entwicklung, Einführung und dem Betrieb moderner umweltfreundlicher Technologien und Anlagen für eine effiziente industrielle Abfallwirtschaft beteiligt.",
      "Our solutions are aimed at reducing environmental impact and ensuring sustainable development.":
        "Unsere Lösungen zielen darauf ab, die Umweltauswirkungen zu verringern und eine nachhaltige Entwicklung zu gewährleisten.",
      "Read more": "Mehr lesen",

      "SM 0/40/70 mixture": "Gemisch aus SM 0/40/70",
      "SM 0/40/70 description":
        "Wir bieten ein neues, hochwertiges Produkt an - ein Mineralgemisch, das sich durch seine fraktionierte Zusammensetzung unterscheidet. Die Technologie wurde unter Mitwirkung des Gasinstituts der Nationalen Akademie der Wissenschaften der Ukraine entwickelt.",
      "The company has its own physical and chemical laboratory for research":
        "Das Unternehmen verfügt über ein eigenes physikalisch-chemisches Labor für die Forschung",
      "Address for correspondence:": "Anschrift für den Schriftverkehr:",
      "Baranivskyi district, Pershotravensk urban-type settlement, 7 Nezalezhnosti str.":
        "Baranivskyi Bezirk, Pershotravensk Siedlung städtischen Typs, 7 Nezalezhnosti str.",
      "Phone:": "Telefon:",
      "Mail:": "E-Mail:",
      "We are on social media:": "Wir sind in den sozialen Medien:",
      Name: "Name",
      Phone: "Telefon",
      Message: "Nachricht",
      Send: "Senden",

      "Mixture of SM 0/40/70": "Mengsel van SM 0/40/70",
      "The SM 0/40/70 mixture description":
        "Das Gemisch SM 0/40/70 ist das Ergebnis der vollständigen Verarbeitung stabilisierter Asche, die lange Zeit in der Aschegrube unter einer Wasserschicht aufbewahrt wurde, durch Trocknung und weitere Abtrennung in einem modernen Produktionskomplex mit fortschrittlicher Technologie.",
      "The separation technology description":
        "Die Abscheidetechnologie wurde unter Beteiligung des Gasinstituts der Nationalen Akademie der Wissenschaften der Ukraine auf der Grundlage einer Computermodellierung des Prozesses und der Berechnung des Verhaltens von Feinpartikeln im Luftstrom entwickelt. Es handelt sich dabei um eine sehr effiziente Fraktionierungsmethode, die zu qualitativ hochwertigen Ergebnissen bei der Verarbeitung von Rohstoffen führt.",
      "Our mineral mixture SM 0/40/70 description":
        "Unser Mineralgemisch SM 0/40/70 ist keine gewöhnliche unbehandelte Flugasche aus TPP-Filtern, sondern ein hochwertiger Zusatzstoff - ein Füllstoff für Zement.",
      "Mixture and concrete": "Mengsel en beton",
      "The introduction of the mixture description":
        "Die Zugabe der Mischung zu Beton (Schaumbeton, Porenbeton) in einer Menge von 15...25 Gew.-% des Zements führt zu einer Verbesserung einer ganzen Reihe von Eigenschaften der Betonmischung und des fertigen Betons.",
      "Due to the spherical shape of the particles description":
        'Aufgrund der kugelförmigen Form der Partikel wirkt das Gemisch als "mineralischer Plastifizierer", der den Wasserbedarf der Betonmischung verringert, was den Zementverbrauch und die Kosten des Betons senkt.',
      "Due to the particle size description":
        "Aufgrund der Partikelgröße von 40...70 Mikrometern (ohne Kohlenstoff) füllt das Gemisch die Poren zwischen größeren Zuschlagstoffen (Sand und Kies) effektiv aus, was die Dichte des Betons erhöht und seine Haltbarkeit, Frostbeständigkeit und Wasserfestigkeit verbessert.",
      "The main differences between the mixture and ash :":
        "Die wichtigsten Unterschiede zwischen dem Gemisch und der Asche :",
      "1. The SM 0/40/70 description":
        '1. Das Gemisch SM 0/40/70 hat einen deutlich geringeren Gehalt an unverbranntem Kohlenstoff ("mechanische Unterverbrennung").',
      "For our product description":
        "Bei unserem Produkt liegt dieser Wert bei maximal 2...3%. Der DSTU-Grenzwert liegt bei höchstens 5%. Die Ascheentfernung aus Filtern beträgt normalerweise 5%...7%.",
      "Unburned carbon (carbon) description":
        "Unverbrannter Kohlenstoff (Kohlenstoff) ist eine sehr unerwünschte Verunreinigung, die die Eigenschaften und Merkmale des Betons verschlechtert, die Widerstandsfähigkeit der Bewehrung verringert und auch das Aussehen des Betons beeinträchtigt (es entstehen schwarze, ölige Flecken).",
      "2. Due to the long exposure description":
        "2. Aufgrund der langen Verweildauer im nassen Zustand enthält das Rohmaterial keinen ungebrannten Kalk und kein Magnesiumoxid, die in frischer Filterasche vorhanden sind und ungleichmäßige Volumenänderungen in Beton und Mörtel verursachen können.",
      "3. Our product is more homogeneous and finer. description":
        "3. Unser Produkt ist homogener und feiner. Die Hauptfraktion der SM 0/40/70-Mischung ist durch einen Rückstand auf einem 45-Mikron-Sieb von etwas mehr als 12 % gekennzeichnet. Wir unterscheiden auch eine Fraktion, die vollständig durch das 45-Mikron-Sieb geht.",

      "Fields of application:": "Anwendungsbereiche:",
      "Production of concrete.": "Herstellung von Beton.",
      "Production of aerated concrete.": "Herstellung von Porenbeton.",
      "Production of cement.": "Herstellung von Zement",
      "Production of construction mixtures.": "Herstellung von Baumischungen",
      "At all stages of production description":
        "In allen Produktionsphasen kontrollieren die technischen Spezialisten von Palleta die Qualität des von uns gelieferten Produkts (chemische Zusammensetzung, physikalische, mechanische und technologische Eigenschaften).",
      "Laboratory testing of raw materials and products (materials)":
        "Laboruntersuchungen von Rohstoffen und Produkten (Materialien)",
      "Quality analysis of raw materials for production":
        "Qualitätsanalyse von Rohstoffen für die Produktion",
      "Selection of recipes for final products":
        "Auswahl von Rezepturen für Endprodukte",
      "Technological consulting": "Technologische Beratung",
      "Conducting joint or cross-validation studies":
        "Durchführung von gemeinsamen oder Kreuzvalidierungsstudien",
      "Geography of supply:": "Geographie der Versorgung:",
      Europe: "Europa",
      Ukraine: "Ukraine",
      "Shipment of products:": "Versand der Produkte:",
      "Road transport:": "Straßenverkehr",
      "Railway transport": "Eisenbahnverkehr",
      "Maritime transport": "Seeverkehr",

      "About Us description 1":
        "8 Jahre Berufserfahrung. Eine der Hauptaktivitäten unseres Unternehmens ist die Förderung und Umsetzung der neuesten Technologien, die dazu beitragen, die Auswirkungen und die Belastung für die Umwelt zu verringern. Durch die Anwendung bewährter Praktiken bemühen wir uns um eine kreislauforientierte Nutzung natürlicher Ressourcen und Industrieabfälle, um die Umwelt zu schonen und die Ökologie zu verbessern.",
      "About Us description 2":
        "Unser Unternehmen entwickelt, implementiert und betreibt in Zusammenarbeit mit der Nationalen Akademie der Wissenschaften der Ukraine und einer Reihe von europäischen Unternehmen moderne umweltfreundliche Technologien und Anlagen für die industrielle Abfallwirtschaft.",
      "About Us description 3":
        "Im Gegensatz zu hoch spezialisierten Unternehmen, die bestimmte Technologien fördern und versuchen, Rohstoffe zu finden, die sich für die Verarbeitung mit diesen Technologien eignen, verfolgen wir einen methodischen Ansatz.",
      "Our values": "Unsere Werte",
      "We are guided by the company's values in our daily work. OUR VALUES:":
        "Unsere tägliche Arbeit wird von unseren Unternehmenswerten geleitet. UNSERE WERTE:",
      "ATTENTION TO DETAIL": "LIEBE ZUM DETAIL",
      RESPONSIBILITY: "VERANTWORTUNG",
      "Innovation and quality": "Innovation und Qualität",
      "Transparency and ethics": "Transparenz und Ethik",
      "Teamwork and cooperation": "Teamarbeit und Kooperation",
      "Corporate newsletter": "Unternehmens-Newsletter ",
      "Ilya Yanishyn:": " Ilja Janischin:",
      "Nature conservation is the responsibility of each of us.":
        "Der Schutz der Natur liegt in der Verantwortung eines jeden von uns.",
      "Environmental awareness is the key to a healthy future.":
        "Umweltbewusstsein ist der Schlüssel zu einer gesunden Zukunft.",
      "Let's take care of nature today to preserve it for future generations.":
        "Kümmern wir uns heute um die Natur, um sie für künftige Generationen zu erhalten.",

      "Name is required": "Naam is vereist",
      "Phone is required": "Telefoon is vereist",
      "Email is required": "E-mail is vereist",
      "Invalid phone number format": "Ongeldig formaat telefoonnummer",
      "Invalid email address": "Ongeldig e-mailadres",
      "Message is required": "Bericht is vereist",
      "Thank you for contacting us, please wait for a call":
        "Bedankt dat u contact met ons opneemt, wacht op een telefoontje",
    },
  },
  pl: {
    translation: {
      Main: "główny",
      Product: "O produkcie",
      "About Us": "o nas",
      Contacts: "Kontakty",
      "Contact us": "Skontaktuj się z nami",
      "With care for the environment": "Dbałość o środowisko",
      "Our company description":
        " Nasza firma, we współpracy z Narodową Akademią Nauk Ukrainy i wieloma europejskimi firmami, aktywnie angażuje się w rozwój, wdrażanie i eksploatację nowoczesnych, przyjaznych dla środowiska technologii i urządzeń do efektywnego zarządzania odpadami przemysłowymi.",
      "Our solutions are aimed at reducing environmental impact and ensuring sustainable development.":
        "Nasze rozwiązania mają na celu zmniejszenie wpływu na środowisko i zapewnienie zrównoważonego rozwoju.",
      "Read more": "Czytaj więcej",

      "SM 0/40/70 mixture": "Mieszanka SM 0/40/70",
      "SM 0/40/70 description":
        "Oferujemy nowy, wysokiej jakości produkt - mieszankę mineralną zróżnicowaną pod względem składu frakcyjnego. Technologia została opracowana przy udziale Instytutu Gazownictwa Narodowej Akademii Nauk Ukrainy.",
      "The company has its own physical and chemical laboratory for research":
        "Firma posiada własne laboratorium fizyczne i chemiczne do badań",
      "Address for correspondence:": "Adres do korespondencji:",
      "Baranivskyi district, Pershotravensk urban-type settlement, 7 Nezalezhnosti str.":
        "Rejon Baranivskyi, osada typu miejskiego Pershotravensk, ulica Nezalezhnosti 7.",
      "Phone:": "Telefon:",
      "Mail:": "Mail:",
      "We are on social media:": "Jesteśmy w mediach społecznościowych:",
      Name: "Imię",
      Phone: "Telefon",
      Message: "Wiadomość",
      Send: "Wysyłać",

      "Mixture of SM 0/40/70": "Mieszanka SM 0/40/70",
      "The SM 0/40/70 mixture description":
        "Mieszanka SM 0/40/70 jest wynikiem całkowitego przetworzenia ustabilizowanego popiołu, który był przechowywany w popielniku pod warstwą wody przez długi czas, poprzez suszenie i dalszą separację w nowoczesnym kompleksie produkcyjnym przy użyciu zaawansowanej technologii.",
      "The separation technology description":
        "Technologia separacji została opracowana przy udziale Instytutu Gazownictwa Narodowej Akademii Nauk Ukrainy w oparciu o komputerowe modelowanie procesu i obliczenia zachowania drobnych cząstek w przepływie powietrza. Jest to bardzo wydajna metoda frakcjonowania, która zapewnia najwyższą jakość przetwarzania surowców.",
      "Our mineral mixture SM 0/40/70 description":
        "Nasza mieszanka mineralna SM 0/40/70 nie jest zwykłym nieprzetworzonym popiołem lotnym z filtrów TPP, ale wysokiej jakości dodatkiem - wypełniaczem do cementu.",
      "Mixture and concrete": "Mieszanka i beton",
      "The introduction of the mixture description":
        "Wprowadzenie mieszanki do betonu (pianobetonu, gazobetonu) w ilości 15...25% masy cementu prowadzi do poprawy całego szeregu właściwości mieszanki betonowej i gotowego betonu.",
      "Due to the spherical shape of the particles description":
        'Ze względu na kulisty kształt cząstek, mieszanka działa jak "mineralny plastyfikator", zmniejszając zapotrzebowanie mieszanki betonowej na wodę, co zmniejsza zużycie cementu i obniża koszt betonu',
      "Due to the particle size description":
        "Ze względu na wielkość cząstek wynoszącą 40...70 mikronów (przy braku węgla), mieszanka skutecznie wypełnia pory między większymi kruszywami (piaskiem i żwirem), co zwiększa gęstość betonu, poprawia jego trwałość, mrozoodporność i wodoodporność.",
      "The main differences between the mixture and ash :":
        "Główne różnice między mieszanką a popiołem:",
      "1. The SM 0/40/70 description":
        '1. Mieszanka SM 0/40/70 ma znacznie niższą zawartość niespalonego węgla ("mechaniczne niedopalanie").',
      "For our product description":
        "W przypadku naszego produktu jest to maksymalnie 2...3%. Limit DSTU wynosi nie więcej niż 5%. Usuwanie popiołu z filtrów wynosi zwykle 5%...7%.",
      "Unburned carbon (carbon) description":
        "Niespalony węgiel (karbon) jest bardzo niepożądanym zanieczyszczeniem, które pogarsza właściwości i charakterystykę betonu, zmniejsza wytrzymałość zbrojenia, a także psuje wygląd betonu (pojawiają się czarne tłuste plamy).",
      "2. Due to the long exposure description":
        "2. Ze względu na długą ekspozycję w stanie mokrym, surowiec nie zawiera niespalonego wapna i tlenku magnezu, które są obecne w świeżym popiele filtracyjnym i mogą powodować nierównomierne zmiany objętości w betonie i zaprawach.",
      "3. Our product is more homogeneous and finer. description":
        "3. Nasz produkt jest bardziej jednorodny i drobniejszy. Główna frakcja mieszanki SM 0/40/70 charakteryzuje się pozostałością na sicie 45 mikronów wynoszącą nieco ponad 12%. Wyróżniamy również frakcję, która całkowicie przechodzi przez sito 45 mikronów.",

      "Fields of application:": "Obszary zastosowań:",
      "Production of concrete.": "Produkcja betonu.",
      "Production of aerated concrete.": "Produkcja betonu komórkowego.",
      "Production of cement.": "Produkcja cementu.",
      "Production of construction mixtures.":
        "Produkcja mieszanek budowlanych.",
      "At all stages of production description":
        "Na wszystkich etapach produkcji specjaliści techniczni Palleta kontrolują jakość dostarczanego przez nas produktu (skład chemiczny, właściwości fizyczne, mechaniczne i technologiczne).",
      "Laboratory testing of raw materials and products (materials)":
        "Badania laboratoryjne surowców i produktów (materiałów)",
      "Quality analysis of raw materials for production":
        "Analiza jakości surowców do produkcji",
      "Selection of recipes for final products":
        "Dobór receptur dla produktów końcowych",
      "Technological consulting": "Doradztwo technologiczne",
      "Conducting joint or cross-validation studies":
        "Prowadzenie wspólnych lub krzyżowych badań walidacyjnych",
      "Geography of supply:": "Geografia podaży:",
      Europe: "Europa",
      Ukraine: "Ukraina",
      "Shipment of products:": "Wysyłka produktów:",
      "Road transport:": "Transport drogowy",
      "Railway transport": "Transport kolejowy",
      "Maritime transport": "Transport morski",

      "About Us description 1":
        "8 lat doświadczenia zawodowego. Jednym z głównych działań naszej firmy jest promowanie i wdrażanie najnowszych technologii, które pomagają zmniejszyć wpływ i obciążenie środowiska. Stosując najlepsze praktyki, staramy się zapewnić obiegowe wykorzystanie zasobów naturalnych i odpadów przemysłowych, dbając o środowisko i poprawiając ekologię.",
      "About Us description 2":
        "Nasza firma, we współpracy z Narodową Akademią Nauk Ukrainy i wieloma europejskimi firmami, opracowuje, wdraża i obsługuje nowoczesne, przyjazne dla środowiska technologie i urządzenia do zarządzania odpadami przemysłowymi.",
      "About Us description 3":
        "W przeciwieństwie do wysoce wyspecjalizowanych firm, które promują określone technologie i próbują znaleźć surowce odpowiednie do przetwarzania przy użyciu tych technologii, my stosujemy podejście metodologiczne.",
      "Our values": "Nasze wartości",
      "We are guided by the company's values in our daily work. OUR VALUES:":
        "W codziennej pracy kierujemy się wartościami naszej firmy. NASZE WARTOŚCI:",
      "ATTENTION TO DETAIL": "DBAŁOŚĆ O SZCZEGÓŁY",
      RESPONSIBILITY: "ODPOWIEDZIALNOŚĆ",
      "Innovation and quality": "Innowacyjność i jakość",
      "Transparency and ethics": "Przejrzystość i etyka",
      "Teamwork and cooperation": "Praca zespołowa i współpraca",
      "Corporate newsletter": "Biuletyn firmowy",
      "Ilya Yanishyn:": "Ilya Yanishin:",
      "Nature conservation is the responsibility of each of us.":
        "Ochrona przyrody jest obowiązkiem każdego z nas.",
      "Environmental awareness is the key to a healthy future.":
        "Świadomość ekologiczna jest kluczem do zdrowej przyszłości.",
      "Let's take care of nature today to preserve it for future generations.":
        "Dbajmy o przyrodę już dziś, aby zachować ją dla przyszłych pokoleń.",

      "Name is required": "Nazwa jest wymagana",
      "Phone is required": "Wymagany jest telefon",
      "Email is required": "Wymagany jest adres e-mail",
      "Invalid phone number format": "Ongeldig formaat telefoonnummer",
      "Invalid email address": "Nieprawidłowy format numeru telefonu",
      "Message is required": "Wiadomość jest wymagana",
      "Thank you for contacting us, please wait for a call":
        "Dziękujemy za kontakt, czekamy na telefon",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "uk",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
